import { fetchWithAuthorizationAndFile, fetch } from "./api";

export const changeDemandStatusAndNotifyUser = async (
  demand_id,
  status,
  token
) => {
  const form = new FormData();

  form.append("demand_id", demand_id);

  form.append("status", status);

  return await fetchWithAuthorizationAndFile.post(
    `/demand/change/status/and/notify`,
    form,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getDemandsByType = async (username, demandType, token) => {
  return await fetch.get(`/demand/customer/all`, {
    params: { email: username, type: demandType },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllDemandsByType = async (demandType, token) => {
  return await fetch.get(`/demand/all/demands`, {
    params: { type: demandType },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProcessingDemandsByType = async (
  username,
  demandType,
  token
) => {
  return await fetch.get(`/demand/get/all/processing/demands`, {
    params: { email: username, type: demandType },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const EditDocument = async (file, document_id, demand_id, token) => {
  const form = new FormData();

  form.append("file", file);

  form.append("document_id", document_id);

  form.append("demand_id", demand_id);

  return await fetchWithAuthorizationAndFile.post("/demand/upload", form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const CloseDemand = async (file, demand_id, token) => {
  let formData = new FormData();

  formData.append("file", file);

  formData.append("demand_id", demand_id);

  return await fetchWithAuthorizationAndFile.post(`/demand/close`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDemand = async (demand_id, token) => {
  return await fetch.get(`/demand/get/one`, {
    params: { number: demand_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const SubmitDemand = async (demand_id, token) => {
  return await fetch.get(`/demand/submit`, {
    params: { number: demand_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const TreatDemand = async (demand_id, username, token) => {
  return await fetch.get(`/demand/process`, {
    params: { number: demand_id, agent: username },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ChangeOperator = async (demand_id, username, token) => {
  return await fetch.get(`/demand/change/operator`, {
    params: { number: demand_id, agent: username },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const CloseProcessingDemand = async (demand_id, token) => {
  return await fetch.get(`/demand/close/processing`, {
    params: { number: demand_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ValidateDocument = async (demand_id, doc_id, token) => {
  return await fetch.get(`/demand/validate/document`, {
    params: { demandId: demand_id, docId: doc_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DeleteDocument = async (demand_id, doc_id, token) => {
  return await fetch.get(`/demand/delete/document`, {
    params: { demandId: demand_id, docId: doc_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const RejectDocument = async (demand_id, doc_id, raison, token) => {
  return await fetch.get(`/demand/reject/document`, {
    params: { demandId: demand_id, docId: doc_id, raison: raison },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ReOpenDemand = async (number, token) => {
  return await fetch.get(`/demand/reopen`, {
    params: { number: number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DonwloadFile = async (data, token) => {
  return await fetch.get(`/demand/download/files/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const RsfNotify = async (demand_id, token) => {
  return await fetch.get(`/demand/rsf/notify`, {
    params: { demandId: demand_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetRsfDoc = async (username, token) => {
  return await fetch.get(`/demand/get/rsf`, {
    params: { username: username },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadPreuvePostulat = async (file, demand_id, token) => {
  let formData = new FormData();

  formData.append("file", file);

  formData.append("demand_id", demand_id);

  return await fetchWithAuthorizationAndFile.post(
    `/demand/upload/preuve/postulat`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const setDueDate = async (dueDate, demand_id, token) => {
  let formData = new FormData();

  formData.append("dueDate", dueDate);

  formData.append("demandId", demand_id);

  return await fetchWithAuthorizationAndFile.post(
    `/demand/set/due-date`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const setPrice = async (demand_id, price, token) => {
  let formData = new FormData();

  formData.append("price", price);

  formData.append("demandId", demand_id);

  return await fetchWithAuthorizationAndFile.post(
    `/demand/set/price`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const setDocumentPublic = async (token) => {
  return await fetch.get(`/demand/set/document/public`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleValidClient = async (email, status, operator, token) => {
  return await fetch.get("/demand/client/valid", {
    params: { email: email, status: status, operator: operator },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const resendEmailVerification = async (email, token) => {
  return await fetch.get("/auth/resend/validate/email", {
    params: { email: email },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
